export abstract class Configuration {
    isProduction: boolean;
    entryPointUrl: string;
    links: {
        portfolio: string;
        pmp: string;
        pricing: string;
        campaign: string;
        pixel: string;
        pam: string;
        coreReporting: string;
        oldReporting: string;
        legacySSP: string;
        helpDeskLink: string;
        statusPage: string;
        inventory: string;
    };
}
