import {Injectable} from '@angular/core';
import {LoggedUserData, SideNavigationAuth} from '@stroeer-labs/side-navigation-ui';
import {AccessibleProductsService} from '../authentication/accessible-products.service';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class DashboardSideNavigationAuthService extends SideNavigationAuth {
    public constructor(private accessibleProductsService: AccessibleProductsService) {
        super();
    }

    public getLoggedUserData(): Observable<LoggedUserData> {
        return this.accessibleProductsService.getAccessibleProducts().pipe(
            map((grantedSystemIds) => ({
                userLabel: '',
                grantedSystemIds,
            })),
            catchError(() => of({userLabel: ''}))
        );
    }

    public logout(): void {
        return;
    }
}
