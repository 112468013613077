import {Configuration} from './configuration';

export function configFactory(): Configuration {
    const origin = window.location.origin;
    const entryPointUrl = `${origin}/product-access-management/api`;

    return {
        isProduction: true,
        entryPointUrl,
        links: {
            portfolio: '/portfolio-management/',
            pmp: '/private-marketplace/',
            pricing: '/pricing/',
            campaign: '/campaign-management/',
            pixel: '/pixel-management/',
            pam: '/product-access-management/',
            coreReporting: '/reporting/',
            oldReporting: 'http://reporting.adscale.de/mydashboard',
            legacySSP: 'https://login.adscale.de/signin?6',
            helpDeskLink: 'https://stroeerdigitalgroup.atlassian.net/servicedesk/customer/portal/6',
            statusPage: 'https://stroeer-core.statuspage.io/',
            inventory: '/inventory/',
        },
    };
}
