import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {ActionServiceModule, EntryPoint} from '@stroeer-labs/action-service';
import {IbbSnackBarPopupInfoModule} from '@stroeer-labs/shared-ui';
import {SwitchAccountsAuthService} from '@stroeer-labs/switch-accounts';
import {DashboardIconRegistryModule} from '../application-imports/angular.icon-registry.module';
import {configFactory} from '../environments/configurations/config';
import {Configuration} from '../environments/configurations/configuration';
import {provider} from '../environments/dependencies/provider';
import {AppComponent} from './app.component';
import {AccessibleProductsService} from './authentication/accessible-products.service';
import {DashboardSwitchAccountsAuthService} from './authentication/dashboard-switch-accounts-auth.service';
import {LinkCardComponent} from './link-card/link-card.component';
import {LinkCardService} from './link-card/link-card.service';
import {DashboardSideNavigationAuthService} from './side-navigation-auth/dashboard-side-navigation-auth.service';
import {SystemNameComponent} from './system-name/system-name.component';

@NgModule({
    declarations: [AppComponent, LinkCardComponent, SystemNameComponent],
    bootstrap: [AppComponent],
    imports: [
        ActionServiceModule,
        BrowserModule,
        BrowserAnimationsModule,
        DashboardIconRegistryModule,
        FlexLayoutModule,
        MatCardModule,
        MatButtonModule,
        RouterModule.forRoot([]),
        HttpClientModule,
        IbbSnackBarPopupInfoModule,
        ...provider.imports,
    ],
    providers: [
        {provide: Configuration, useFactory: configFactory},
        {provide: EntryPoint, useFactory: (c: Configuration): {host: string} => ({host: c.entryPointUrl}), deps: [Configuration]},
        {provide: SwitchAccountsAuthService, useClass: DashboardSwitchAccountsAuthService},
        LinkCardService,
        AccessibleProductsService,
        DashboardSwitchAccountsAuthService,
        DashboardSideNavigationAuthService,
        ...provider.providers,
    ],
})
export class AppModule {}
