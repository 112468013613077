import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@NgModule({
    imports: [CommonModule, MatIconModule, HttpClientModule],
    exports: [MatIconModule],
})
export class DashboardIconRegistryModule {
    private contentPath = 'assets/icons/svg';

    constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
        this.addSvgIcon('ibb-portfolio-management', '/av/ic_web_24px.svg');
        this.addSvgIcon('ibb-pricing', '/action/baseline-euro_symbol-24px.svg');
        this.addSvgIcon('ibb-campaign-management', '/action/bullhorn.svg');
        this.addSvgIcon('ibb-pixel-management', '/action/ibb-street-view.svg');
        this.addSvgIcon('ibb-product-access-management', '/action/supervisor_account-24px.svg');
        this.addSvgIcon('ibb-aod', '/device/aod_24dp.svg');
        this.addSvgIcon('ibb-chart-line', '/content/ic_chart_line_24px.svg');
        this.addSvgIcon('ibb-check', '/navigation/ic_check_24px.svg');
        this.addSvgIcon('ibb-helpdesk', '/other/lifebuoy_24px.svg');
        this.addSvgIcon('ibb-status-page', '/other/wifi_2_bar_24px.svg');
    }

    private addSvgIcon(iconName: string, url: string): void {
        this.iconRegistry.addSvgIcon(iconName, this.sanitizer.bypassSecurityTrustResourceUrl(`${this.contentPath}${url}`));
    }
}
