import {AuthenticationService} from '@stroeer-labs/authentication-ui';
import {Injectable} from '@angular/core';
import {SwitchAccountsAuthService} from '@stroeer-labs/switch-accounts';

@Injectable()
export class DashboardSwitchAccountsAuthService extends SwitchAccountsAuthService {
    public constructor(private authService: AuthenticationService) {
        super();
    }

    public logout(): void {
        this.authService.logout();
    }

    public changeRepresentation(accountId: string): void {
        this.authService.changeRepresentation(accountId);
    }
}
