import {Component, Input} from '@angular/core';
import {LinkCardItem} from './link-card.service';

@Component({
    selector: 'app-link-card',
    templateUrl: './link-card.component.html',
    styleUrls: ['./link-card.component.scss'],
})
export class LinkCardComponent {
    @Input() item: LinkCardItem;
}
