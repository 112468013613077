import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-system-name',
    templateUrl: './system-name.component.html',
})
export class SystemNameComponent {
    @Input()
    public systemId: string;
}
