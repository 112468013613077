import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IbbPopupInfoService} from '@stroeer-labs/shared-ui';
import {BaseSideNavItem, SystemNavigationId} from '@stroeer-labs/side-navigation-ui';
import {Observable} from 'rxjs';
import {filter, first, map} from 'rxjs/operators';
import {LinkCardItem, LinkCardService} from './link-card/link-card.service';
import {DashboardSideNavigationAuthService} from './side-navigation-auth/dashboard-side-navigation-auth.service';
import {AuthenticationService} from '@stroeer-labs/authentication-ui';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    @ViewChild('forbiddenSystem', {static: true})
    private forbiddenSystemTemplate: TemplateRef<unknown>;

    @ViewChild('unknownReason', {static: true})
    private unknownReasonTemplate: TemplateRef<unknown>;

    public activeProjectItem: BaseSideNavItem = {
        itemId: SystemNavigationId.dashboard,
        destination: '/',
    };
    public productCards: ReadonlyArray<LinkCardItem>;
    public linkCards: ReadonlyArray<LinkCardItem>;

    // eslint-disable-next-line max-params
    constructor(
        private linkCardService: LinkCardService,
        private sideNavigationAuthService: DashboardSideNavigationAuthService,
        private authService: AuthenticationService,
        private activatedRoute: ActivatedRoute,
        private popupService: IbbPopupInfoService,
        private router: Router
    ) {
        this.productCards = this.linkCardService.buildProductCardItems();
        this.linkCards = this.linkCardService.buildLinkCardItems();
    }

    public showSwitchAccounts(): Observable<boolean> {
        return this.sideNavigationAuthService.getLoggedUserData().pipe(map((loggedUser) => !!loggedUser.grantedSystemIds));
    }

    public ngOnInit(): void {
        this.activatedRoute.queryParams
            .pipe(
                filter((queryParams) => queryParams['unable-to-access']),
                map((queryParams) => queryParams['unable-to-access']),
                first()
            )
            .subscribe((forbiddenSystemId) => this.handleAccessError(forbiddenSystemId));
    }

    public login(): void {
        this.authService.logout();
    }

    private handleAccessError(forbiddenSystemId: unknown): void {
        this.clearQueryParams();

        return this.popupService.open(this.getTemplate(forbiddenSystemId), {
            data: {forbiddenSystemId},
            durationInMs: 10 * 1000,
        });
    }

    private clearQueryParams(): void {
        this.router.navigate([], {
            queryParams: {},
        });
    }

    private getTemplate(forbiddenSystemId: unknown): TemplateRef<unknown> {
        return forbiddenSystemId === 'unknown' ? this.unknownReasonTemplate : this.forbiddenSystemTemplate;
    }
}
