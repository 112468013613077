<a
    class="link-card"
    href="{{ item.link }}"
    [target]="item.linkTarget"
    [ngClass]="{ 'disabled-content': item.disabled | async }">
    <mat-card appearance="outlined">
        <img mat-card-image src="{{ item.image }}" *ngIf="item.image" />
        <mat-card-header>
            <mat-card-title>
                <mat-icon svgIcon="{{ item.icon }}"></mat-icon>
                <span>
                    <app-system-name [systemId]="item.localizationId"></app-system-name>
                </span>
            </mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <span>
                {item.localizationId, select, system-portfolio-management {Manage your websites and ad slots.
                <br />Ready for banners, in stream and in-read video.} system-pricing {Set up or edit your price
                lists.<br />Raise your earning by flexible price rules.} system-private-marketplace {Manage your private
                deals and inventory<br />bundles.} system-campaign-management {Manage your campaigns and creatives.
                <br />&nbsp;} system-pixel-management {Manage your tracking codes.<br />&nbsp;}
                system-product-access-management {Invite others and manage their access to Core tools.} system-inventory
                {Manage your Inventory packages.} system-reporting {DSP and SSP reporting.} legacy-ssp-ui {Stroeer SSP}
                help {Contact our customer care} status-page {Operational state of Stroeer Core} }
            </span>
        </mat-card-content>
    </mat-card>
</a>
