import {Injectable} from '@angular/core';
import {ActionExecutor} from '@stroeer-labs/action-service';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {first, map} from 'rxjs/operators';

@Injectable()
export class AccessibleProductsService {
    private readonly accessibleSystems: Subject<ReadonlyArray<string>>;

    public constructor(private actionExecutor: ActionExecutor) {
        this.accessibleSystems = new ReplaySubject();
        this.actionExecutor
            .execute<ReadonlyArray<string>>('ShowAccessibleSystems', {})
            .pipe(
                first(),
                map((result) => result.data)
            )
            .subscribe(this.accessibleSystems);
    }

    public getAccessibleProducts(): Observable<ReadonlyArray<string>> {
        return this.accessibleSystems;
    }
}
