<div fxFill fxLayout="row">
    <ibb-cross-projects-side-navigation
        [activeProjectItem]="activeProjectItem"
        signOutText="Sign out"></ibb-cross-projects-side-navigation>

    <div fxFlex fxLayout="column">
        <header class="header mat-elevation-z6" fxLayout="row">
            <div class="header-content" fxFlex fxLayout="column">
                <div class="line" fxLayoutAlign="start center">
                    <h1>Dashboard</h1>
                </div>
            </div>
            <ibb-switch-accounts
                system_id="system_portfolio_management"
                data-test="switch-accounts"
                fxLayout="column"
                *ngIf="showSwitchAccounts() | async; else loginButton">
            </ibb-switch-accounts>
        </header>
        <div></div>
        <div class="main-content">
            <div class="products-list">
                <app-link-card class="product-card" *ngFor="let data of productCards" [item]="data"></app-link-card>
            </div>
            <div class="main-content-subdivider">
                <h3>You may also need</h3>
            </div>
            <div class="products-list">
                <app-link-card class="product-card" *ngFor="let data of linkCards" [item]="data"></app-link-card>
            </div>
        </div>
    </div>
</div>

<ng-template #forbiddenSystem let-data>
    <div style="text-align: center">
        <p>
            You don't have access to
            <app-system-name [systemId]="data.forbiddenSystemId"></app-system-name>
            ! Try to switch representation or log in as another user.
        </p>
    </div>
</ng-template>

<ng-template #unknownReason>
    <div style="text-align: center">
        <p>
            Access to requested system denied due to unknown reason. Try to switch representation or log in as another
            user, or
            <a href="https://stroeerdigitalgroup.atlassian.net/servicedesk/customer/portal/6">contact our support</a>
        </p>
    </div>
</ng-template>

<ng-template #loginButton>
    <div style="text-align: center">
        <button (click)="login()" mat-flat-button data-test="login-button">Login</button>
    </div>
</ng-template>
