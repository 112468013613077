import {Injectable} from '@angular/core';
import {noop, Observable, of, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Configuration} from '../../environments/configurations/configuration';
import {AccessibleProductsService} from '../authentication/accessible-products.service';

export enum LinkTarget {
    SAME_TAB = '_self',
    NEW_TAB = '_blank',
}

export enum LinkItemId {
    POM = 'system-portfolio-management',
    PRM = 'system-pricing',
    PMP = 'system-private-marketplace',
    PXM = 'system-pixel-management',
    DSP = 'system-campaign-management',
    PAM = 'system-product-access-management',
    INV = 'system-inventory',
    CORE_REPORTING = 'system-reporting',
    LEGACY_SSP = 'legacy-ssp-ui',
    HELP = 'help',
    STATUS_PAGE = 'status-page',
}

export enum SystemCampaignManagementId {
    productionAndDevel = '45273277-a3f9-4086-ba40-8d8ced92499b',
    staging = 'af9efd10-840b-4757-947c-ee8d85e778d2',
}

export enum CoreReportingId {
    ssp = 'system-ssp-reporting',
    dsp = 'system-dsp-reporting',
}

export interface LinkCardItem {
    icon: string;
    localizationId: LinkItemId;
    image?: string;
    link: string;
    linkTarget: LinkTarget;
    disabled: Observable<boolean>;
}

@Injectable()
export class LinkCardService {
    private static readonly newSystems = [
        LinkItemId.POM,
        LinkItemId.PRM,
        LinkItemId.PMP,
        LinkItemId.DSP,
        LinkItemId.PXM,
        LinkItemId.PAM,
        LinkItemId.INV,
        LinkItemId.CORE_REPORTING,
    ];
    private isCardDisabled: Map<LinkItemId, Subject<boolean>>;

    public constructor(private accessibleProductsService: AccessibleProductsService, private configuration: Configuration) {
        this.initIsCardDisabled();
        this.updateCardStatus();
    }

    public buildLinkCardItems(): ReadonlyArray<LinkCardItem> {
        return [
            {
                localizationId: LinkItemId.LEGACY_SSP,
                icon: 'ibb-portfolio-management',
                link: this.configuration.links.legacySSP,
                linkTarget: LinkTarget.NEW_TAB,
                disabled: of(false),
            },
            {
                localizationId: LinkItemId.HELP,
                icon: 'ibb-helpdesk',
                link: this.configuration.links.helpDeskLink,
                linkTarget: LinkTarget.NEW_TAB,
                disabled: of(false),
            },
            {
                localizationId: LinkItemId.STATUS_PAGE,
                icon: 'ibb-status-page',
                link: this.configuration.links.statusPage,
                linkTarget: LinkTarget.NEW_TAB,
                disabled: of(false),
            },
        ];
    }

    public buildProductCardItems(): ReadonlyArray<LinkCardItem> {
        return [
            {
                localizationId: LinkItemId.POM,
                icon: 'ibb-portfolio-management',
                image: 'assets/images/pom.png',
                link: this.configuration.links.portfolio,
                linkTarget: LinkTarget.SAME_TAB,
                disabled: this.isCardDisabled.get(LinkItemId.POM).asObservable(),
            },
            {
                localizationId: LinkItemId.PRM,
                icon: 'ibb-pricing',
                image: 'assets/images/prm.png',
                link: this.configuration.links.pricing,
                linkTarget: LinkTarget.SAME_TAB,
                disabled: this.isCardDisabled.get(LinkItemId.PRM).asObservable(),
            },
            {
                localizationId: LinkItemId.PMP,
                icon: 'ibb-private-marketplace',
                image: 'assets/images/pmp.png',
                link: this.configuration.links.pmp,
                linkTarget: LinkTarget.SAME_TAB,
                disabled: this.isCardDisabled.get(LinkItemId.PMP).asObservable(),
            },
            {
                localizationId: LinkItemId.DSP,
                icon: 'ibb-campaign-management',
                image: 'assets/images/dsp.png',
                link: this.configuration.links.campaign,
                linkTarget: LinkTarget.SAME_TAB,
                disabled: this.isCardDisabled.get(LinkItemId.DSP).asObservable(),
            },
            {
                localizationId: LinkItemId.PXM,
                icon: 'ibb-pixel-management',
                image: 'assets/images/pxm.png',
                link: this.configuration.links.pixel,
                linkTarget: LinkTarget.SAME_TAB,
                disabled: this.isCardDisabled.get(LinkItemId.PXM).asObservable(),
            },
            {
                localizationId: LinkItemId.PAM,
                icon: 'ibb-product-access-management',
                image: 'assets/images/pam.png',
                link: this.configuration.links.pam,
                linkTarget: LinkTarget.SAME_TAB,
                disabled: this.isCardDisabled.get(LinkItemId.PAM).asObservable(),
            },
            {
                localizationId: LinkItemId.CORE_REPORTING,
                icon: 'ibb-chart-line',
                image: 'assets/images/reporting.png',
                link: this.configuration.links.coreReporting,
                linkTarget: LinkTarget.SAME_TAB,
                disabled: this.isCardDisabled.get(LinkItemId.CORE_REPORTING).asObservable(),
            },
            {
                localizationId: LinkItemId.INV,
                icon: 'ibb-aod',
                image: 'assets/images/inv.png',
                link: this.configuration.links.inventory,
                linkTarget: LinkTarget.SAME_TAB,
                disabled: this.isCardDisabled.get(LinkItemId.INV).asObservable(),
            },
        ];
    }

    private initIsCardDisabled(): void {
        this.isCardDisabled = new Map();
        LinkCardService.newSystems.forEach((linkedItemId) => this.isCardDisabled.set(linkedItemId, new Subject()));
    }

    private updateCardStatus(): void {
        this.accessibleProductsService
            .getAccessibleProducts()
            .pipe(map((accessibleSystems: ReadonlyArray<string>) => this.translateAccessibleSystems(accessibleSystems)))
            .subscribe({
                next: (accessibleSystems) => {
                    LinkCardService.newSystems.forEach((linkedItemId) => {
                        this.disableCardIfUserDoesNotHaveAccessToTheSystem(accessibleSystems, linkedItemId);
                    });
                },
                error: noop,
            });
    }

    private disableCardIfUserDoesNotHaveAccessToTheSystem(accessibleSystems: ReadonlyArray<string>, linkItemId: LinkItemId): void {
        if (!accessibleSystems.includes(linkItemId)) {
            this.isCardDisabled.get(linkItemId).next(true);
        }
    }

    private translateAccessibleSystems(accessibleSystems: ReadonlyArray<string>): ReadonlyArray<string> {
        return accessibleSystems.map((systemId) => {
            if (this.isSystemCampaignManagement(systemId)) {
                return LinkItemId.DSP;
            } else if (this.isCoreReporting(systemId)) {
                return LinkItemId.CORE_REPORTING;
            } else {
                return systemId;
            }
        });
    }

    private isSystemCampaignManagement(systemId: string): boolean {
        return systemId === SystemCampaignManagementId.productionAndDevel || systemId === SystemCampaignManagementId.staging;
    }

    private isCoreReporting(systemId: string): boolean {
        return systemId === CoreReportingId.dsp || systemId === CoreReportingId.ssp;
    }
}
