import {EntryPointActionExecutor} from '@stroeer-labs/action-service';
import {GatekeeperAuthenticationModule} from '@stroeer-labs/authentication-ui';
import {IbbSideNavigationModule, ProductCategory} from '@stroeer-labs/side-navigation-ui';
import {IbbSwitchAccountsModule, SetDefaultAccountActionExecutor, UserRepresentationActionExecutor} from '@stroeer-labs/switch-accounts';
import {DashboardSideNavigationAuthService} from '../../app/side-navigation-auth/dashboard-side-navigation-auth.service';
import {DependencyProvider} from './dependency-provider';

export const provider: DependencyProvider = {
    imports: [
        IbbSideNavigationModule.forRoot({category: ProductCategory.Other, auth: DashboardSideNavigationAuthService}),
        GatekeeperAuthenticationModule.create({
            systemId: 'system-dashboard',
            baseApplicationUrl: '/dashboard',
        }),
        IbbSwitchAccountsModule,
    ],
    providers: [
        {provide: UserRepresentationActionExecutor, useClass: EntryPointActionExecutor},
        {provide: SetDefaultAccountActionExecutor, useClass: EntryPointActionExecutor},
    ],
};
